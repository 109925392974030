import { Routes } from '@angular/router';
import { RedirectToGuard } from './routing/redirect-to.guard';
import { PageUrls } from './routing/page-urls';

export const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		loadChildren: () => import('./pages/home-page/home-page.module').then((m) => m.HomePageModule),
	},
	{
		path: 'o-nas',
		loadChildren: () => import('./pages/o-nas/o-nas.module').then((m) => m.ONasModule),
	},
	{
		path: 'kariera',
		canActivate: [RedirectToGuard],
		component: RedirectToGuard,
		data: {
			externalUrl: PageUrls.KARIERA_ABSOLUTE_URL,
		},
	},
	{
		path: 'reference',
		loadChildren: () => import('./pages/reference/reference.module').then((m) => m.ReferenceModule),
	},
	{
		path: 'ke-stazeni',
		loadChildren: () => import('./pages/downloads/downloads.module').then((m) => m.DownloadsModule),
	},
	{
		path: 'kontakt',
		loadChildren: () => import('./pages/kontakt/kontakt.module').then((m) => m.KontaktModule),
	},
	{
		path: 'slovnik-pojmu',
		loadChildren: () => import('./pages/glossary/glossary.module').then((m) => m.GlossaryModule),
	},
	{
		path: 'legal/:slug',
		loadChildren: () => import('./pages/terms-and-conditions/terms-and-conditions.module').then((m) => m.TermsAndConditionsModule),
	},
	{
		path: 'reseni',
		children: [
			{
				path: '',
				pathMatch: 'full',
				loadChildren: () => import('./pages/reseni-home/reseni-home.module').then((m) => m.ReseniHomeModule),
			},
			{
				path: ':slug',
				loadChildren: () => import('./pages/reseni-detail/reseni-detail.module').then((m) => m.ReseniDetailModule),
				data: {
					blueHeader: 'yes',
				},
			},
		],
		data: {
			blueHeader: 'children',
		},
	},
	{
		path: 'technologie',
		data: {
			technologie: true,
		},
		children: [
			{
				path: 'prumyslove-myci-technologie-na-miru',
				loadChildren: () => import('./pages/technologie-prumyslove/technologie-prumyslove.module').then((m) => m.TechnologiePrumysloveModule),
			},
			{
				path: 'prumyslove-cistirny-odpadnich-vod',
				loadChildren: () => import('./pages/technologie-cov/technologie-cov.module').then((m) => m.TechnologieCovModule),
			},
			{
				path: 'myci-linky-a-rucni-tlakove-cisteni',
				loadChildren: () => import('./pages/technologie-myci-linky/technologie-myci-linky.module').then((m) => m.TechnologieMyciLinkyModule),
			},
			{
				path: 'samoobsluzne-a-bezkontaktni-myci-boxy',
				loadChildren: () => import('./pages/technologie-myci-boxy/technologie-myci-boxy.module').then((m) => m.TechnologieMyciBoxyModule),
			},
			{
				path: 'specialni-myci-linky',
				loadChildren: () => import('./pages/technologie-specialni-linky/technologie-specialni-linky.module')
					.then((m) => m.TechnologieSpecialniLinkyModule)
				,
			},
			{
				path: '**',
				redirectTo: 'prumyslove-myci-technologie-na-miru',
			},
		],
	},
	{
		path: '**',
		redirectTo: '/',
	}
];
