import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class RedirectToGuard implements CanActivate {
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): boolean {
		if (route.data.externalUrl) {
			window.location.assign(route.data.externalUrl);
			return false;
		}
		return true;
	}

}
